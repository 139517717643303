// @ts-ignore
import "blueimp-canvas-to-blob";
import firebase from "firebase/app";
import "firebase/analytics";
import React from "react";
import ReactDOM from "react-dom";
import ReactPixel from "react-facebook-pixel";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import { FACEBOOK_PIXEL, FIREBASE_CONFIG, PRODUCTION } from "./env";
import "./fonts";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";

try {
  ReactPixel.init(FACEBOOK_PIXEL.id, undefined, {
    autoConfig: false,
    debug: !PRODUCTION,
  });
} catch (error) {}

try {
  const firebaseConfig = FIREBASE_CONFIG;
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
} catch (error) {
  console.error(error);
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
