import { Icon, Modal, notification, Popover } from "antd";
import React, { useCallback, useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TruncateMarkup from "react-truncate-markup";
import { BabyPage, StripeProduct, Baby } from "../../interfaces";
import { AppState, sagaMiddleware } from "../../store";
import { deleteBabyPage, duplicateBabyPage } from "../../store/sagas/babyPage";
import { downloadBabypage } from "../../utils/canvas";
import { replaceThumbURL } from "../../utils/string";
import ModalPlus from "../ModalPlus/ModalPlus";
import PhotoModal from "../PhotoModal/PhotoModal";
import PlusAlert from "../SaveBabyPage/PlusAlert";
import "./BabyPagePreview.scss";
import { useHistory } from "react-router";
import moment from "moment";
const { confirm } = Modal;

type Props = {
  babyPage: BabyPage;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  stripeProducts: StripeProduct[];
  isPlus: boolean;
  hasFullAccess: boolean | undefined;
  isGuestUser: boolean | undefined;
  babies: Baby[];
  babySelected?: Baby;
};

const BabyPagePreview: React.FC<Props> = ({
  babyPage,
  stripeProducts,
  setLoading,
  isPlus,
  hasFullAccess,
  isGuestUser,
  babies,
  babySelected 
}) => {
  const [control, setControl] = useState({ modal: false, popover: false });
  const [showPlus, setShowPlus] = useState(false);
  const [downloadFreeBP, setDownloadFreeBP] = useState(false);
  const history = useHistory();
  const sendToUpgradeToPlus = () => {
    history.push("/cart/subscription/yearly");
  }

  useEffect(() => {
    if (!!downloadFreeBP) {
      setLoading(true);
      downloadBabypage(
        replaceThumbURL(babyPage.thumbnail),
        !!babyPage.thumbnail_content
          ? replaceThumbURL(babyPage.thumbnail_content)
          : "",
        isPlus
      )
        .then(() => {
          setLoading(false);
          setDownloadFreeBP(false);
        })
        .catch(err =>
          Modal.error({
            title: "Error",
            content: "Error generating BabyPage"
          })
        );
    }
  }, [downloadFreeBP, setLoading, babyPage, isPlus])

  const onDelete = useCallback(() => {
    confirm({
      title: (
        <>
          <h3 className="confirmation-question">Are you sure?</h3>
          <p>Do you want to delete this BabyPage?</p>
        </>
      ),
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      width: 300,
      icon: "warning",
      onOk() {
        setLoading(true);

        sagaMiddleware.run<any>(
          deleteBabyPage,
          babyPage.id,
          (err: string | null) => {
            setLoading(false);
            if (err) {
              Modal.error({
                title: "Error",
                content: err
              });
            } else {
              notification.success({
                message: "Success",
                description: "The BabyPage was deleted successfully!"
              });
            }
          }
        );
      }
    });
  }, [babyPage.id, setLoading]);

  const popupContent = (
    <ul className="babypage-actions">
      <li
        className="babypage-action py-1"
        onClick={() => setControl({ modal: true, popover: false })}
      >
        <Icon className="px-1" type="search" />
        Preview
      </li>
      
      {(babyPage.new_version && !!hasFullAccess) && (
        <Link
          className="babypage-detail"
          to={`/babypages/save?id=${babyPage.id}`}
        >
          <li className="babypage-action py-1">
            <Icon className="px-1" type="edit" />
            Edit
          </li>
        </Link>
      )}

      {!isGuestUser && (
        <li
          className="babypage-action py-1"
          onClick={() => {
            setControl(state => ({ ...state, popover: false }));
            onDelete();
          }}
        >
          <Icon className="px-1" type="delete" />
          Delete
        </li>
      )}

      <li
        className="babypage-action py-1"
        onClick={() => {
          if(isPlus) {
            setControl(state => ({ ...state, popover: false }));
          setLoading(true);

          downloadBabypage(
            replaceThumbURL(babyPage.thumbnail),
            !!babyPage.thumbnail_content
              ? replaceThumbURL(babyPage.thumbnail_content)
              : "",
            true,
            `${babyPage.title}Babypage`
          )
            .then(() => {
              setLoading(false);
            })
            .catch(err =>
              Modal.error({
                title: "Error",
                content: "Error generating BabyPage"
              })
            );
          } else {
            setControl(state => ({ ...state, popover: false }));
            setShowPlus(true);
          }
        }}
      >
        <Icon className="px-1" type="cloud-download" />
        Download
        {!isPlus && (
          <PlusAlert preview={true} />
          )}
      </li>
      {(babyPage.new_version && !isGuestUser) && (
        <li
          className="babypage-action py-1"
          onClick={() => {
            setControl(state => ({ ...state, popover: false }));
            setLoading(true);
            const babyPagesCounter = babies.reduce(
              (acc: number, baby: any) => acc + baby.babyPages.length,
              0
            );

            if(babyPagesCounter >= 15 && !isPlus) {
              notification.error({
                message: "Cannot duplicate BabyPage",
                description: "You currently have 15 BabyPäges, if you want to add more subscribe to a PLUS subscription to add unlimited BabyPages"
              });
              setLoading(false);
            } else {
              sagaMiddleware.run<any>(
                duplicateBabyPage,
                babyPage.id,
                (err: string | null) => {
                  setLoading(false);
                  if (err) {
                    Modal.error({
                      title: "Error",
                      content: err
                    });
                  } else {
                    notification.success({
                      message: "Success",
                      description: "The BabyPage was duplicated successfully!"
                    });
                  }
                }
              );
            }           
          }}
        >
          <Icon className="px-1" type="copy" />
          Duplicate
        </li>
      )}
    </ul>
  );

  const onCloseModalPlus = () => {
    setShowPlus(false);
  }


  const title = useMemo(() => {
    if( babyPage.event === "Custom Page" && babyPage.title === "") {
      return (
      <>
        <h1 className="title">{babyPage.event}</h1>
      </>
      )
    }

    else {
      return (
      <>
        <h1 className="title">{babyPage.title}</h1>
      </>
      )
    }
  }, [babySelected, babyPage])

  return (
    <>
      <Popover
        placement="bottom"
        content={popupContent}
        trigger="click"
        visible={control.popover}
        onVisibleChange={popover =>
          setControl(state => ({ ...state, popover }))
        }
      >
        <div className="col-6 col-sm-4 col-md-4 col-lg-3 babypage-preview pr-0">
          <img
            src={babyPage.thumbnail}
            alt={babyPage.title}
            className="square-image babypage-image"
          />
          <div className="babypage-content d-flex flex-column justify-content-center align-items-center">
            <div className="babypage-text  d-flex flex-column ">
            {title}
            <p className="subtitle">
              {!!babyPage.subtitle && babyPage.subtitle}
            </p>
            </div>
           
          </div>
        </div>
      </Popover>
      <PhotoModal
        image={babyPage.thumbnail}
        content={babyPage.thumbnail_content}
        visible={control.modal}
        onClose={() => setControl(state => ({ ...state, modal: false }))}
        babyPage={babyPage}
        hasFullAccess={hasFullAccess}
        isGuestUser={isGuestUser}
      />
      <ModalPlus
        afterSaveBPMsg={true}
        visible={showPlus}
        onClose={() => onCloseModalPlus()}
        onSaveBeforeUpgradeToPlus={sendToUpgradeToPlus}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  isPlus: !!state.user.user_level && state.user.user_level === 1,
  babies: state.baby.babies
});

export default connect(mapStateToProps)(BabyPagePreview);
