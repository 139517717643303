import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Icon, Modal, Radio } from "antd";
import AscendingSort from "../Icons/AscendingSort";
import DescendingSort from "../Icons/DescendingSort";
import SortIcon from '../Icons/SortIcon';
import { RadioChangeEvent } from "antd/lib/radio";
import { capitalize } from "lodash";
import "./SortSelectedBabypages.scss"

interface sortOption {
  value: string;
  label: string;
}

interface Props {
  sortOptions: sortOption[];
  sortBy: string;
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
  dropdownVisible: boolean;
  setDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
  reverseSort: boolean;
  setReverseSort: React.Dispatch<React.SetStateAction<boolean>>;
  setValidate1stReverse: React.Dispatch<React.SetStateAction<boolean>>;
  sortBabypages: Function;
}

const SortSelectedBabypages: React.FC<Props> = ({
    sortOptions,
    sortBy,
    setSortBy,
    dropdownVisible,
    setDropdownVisible,
    reverseSort,
    setReverseSort,
    setValidate1stReverse,
    sortBabypages
  }) => {
    const [modalVisible, setModalVisible] = useState(false)

    return (
      <div className="sort-container clickable" style={{backgroundColor:'#ffffff',  borderRadius: "8px"}} onClick={() => {
        setModalVisible(true)
      }}>
        <span style={{verticalAlign:"middle", backgroundColor:'#ffffff'}} >
       <SortIcon classes="mt-2" />
        </span>
        <Modal 
          visible={modalVisible} 
          onCancel={(e) => { 
            e.stopPropagation();
            setModalVisible(false)
          }} 
          footer={null}>
        <h1 className="sort-title">Sort By</h1>
        <Radio.Group
              value={sortBy}
              style={{
                padding: 0
              }}
              onChange={(e: RadioChangeEvent) => {
               setSortBy(e.target.value);
               sortBabypages(e.target.value)
              }}
            >
              {sortOptions.filter(item => item.value !== '').map ((item, index) =>  (
                <Radio style={{display:"block"}} key={index} value={item.value}> {capitalize(item.label)} </Radio>
              ))}
            </Radio.Group>
            { sortBy !== '' ? (
                <div className="">
                <span
                  className="clickable"
                  onClick={() => {
                    setValidate1stReverse(true);
                    setReverseSort(!reverseSort);
                  }}
                >
                  {!reverseSort ? <DescendingSort /> : <AscendingSort /> }
                </span>
  
                <span className="ml-2">
                  {sortBy === "date"
                    ? !reverseSort ? "Latest First" : "Earliest First"
                    : sortBy === "milestone"
                    ? !reverseSort ? "Earliest Milestones" : "Latest Milestones"
                    : !reverseSort ? "A-Z" : "Z-A"}
                </span>
              </div>
            ) : null }
          
      </Modal>
        </div>
    )
  }

export default SortSelectedBabypages;